import { EventPreviewFragment } from "../../generated/apolloComponents";
import { Card, CardBody, CardFooter, Badge } from "reactstrap";
import Calendar from 'react-feather/dist/icons/calendar';
import MapPin from 'react-feather/dist/icons/map-pin';
import Cast from 'react-feather/dist/icons/cast';
import classNames from 'classnames';


const EventCard: React.FunctionComponent<EventPreviewFragment> = (event) => (
    <Card className={classNames('shadow h-100', {'featured': event.featured || event.promoted, 'border-success': event.online})}>
        {(event.featured || event.promoted) && <div className="p-1 rounded-top text-center featured-bg">Featured Event</div>}
        {event.image && <img width="100%" src={event.image.imageCardPreview!} alt={event.name} className={classNames('img-fluid', {'rounded-top': !event.featured})} />}
        <CardBody>
            <h2 className="h5 font-weight-medium">
              <a href={`/events/${event.slug}`} className="stretched-link">{event.name}</a>
            </h2>
            {event.alternativeName && <div className="mb-2">{event.alternativeName}</div>}
            {event.topics.map(topic => <><Badge color="light" pill={true}>{topic}</Badge> </>)}
        </CardBody>
        <CardFooter className="border-0">
            <div className="d-flex mb-1">
                <Calendar size={16} className="text-secondary mr-2" /><small className="d-block text-secondary mb-1">{event.date}</small>
            </div>
            {event.online && <div className="d-flex">
                <Cast size={16} className="text-success mr-2" /> <small className="d-block text-success mb-1">Online</small>
            </div>}
            {event.place && <div className="d-flex">
                <MapPin size={16} className="text-secondary mr-2" /> <small className="d-block text-secondary mb-1">{event.place.country}{event.place.locality && <>, {event.place.locality}</>}</small>
            </div>}
        </CardFooter>
    </Card>
);

export default EventCard;
