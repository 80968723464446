import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
  DateTime: any,
};

export type AddRemoteEventInput = {
  remoteEventExtractionId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  description: Scalars['String'],
  placeId?: Maybe<Scalars['String']>,
  online: Scalars['Boolean'],
  image?: Maybe<Scalars['String']>,
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  approximateDate?: Maybe<Scalars['String']>,
  url: Scalars['String'],
  speakers?: Maybe<Array<Maybe<AddRemoteSpeakerInput>>>,
  images?: Maybe<Array<Maybe<AddRemoteImageInput>>>,
  partners?: Maybe<Array<Maybe<AddRemotePartnerInput>>>,
  slug?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type AddRemoteEventPayload = {
   __typename?: 'AddRemoteEventPayload',
  event?: Maybe<Event>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type AddRemoteImageInput = {
  src: Scalars['String'],
  alt?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Scalars['Boolean']>,
};

export type AddRemotePartnerInput = {
  name: Scalars['String'],
  partnershipType: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type AddRemoteSpeakerInput = {
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
};

export type ContactUsInput = {
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ContactUsPayload = {
   __typename?: 'ContactUsPayload',
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  message: Scalars['String'],
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  result: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreateNewEventRequestInput = {
  url: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  listingType?: Maybe<Scalars['String']>,
  partnersLink?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreateNewEventRequestPayload = {
   __typename?: 'CreateNewEventRequestPayload',
  newEventRequest?: Maybe<NewEventRequestType>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreatePaymentInput = {
  eventId: Scalars['ID'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type CreatePaymentPayload = {
   __typename?: 'CreatePaymentPayload',
  result: Scalars['String'],
  sessionId?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};



export type ErrorType = {
   __typename?: 'ErrorType',
  field: Scalars['String'],
  messages: Array<Scalars['String']>,
};

export type Event = Node & {
   __typename?: 'Event',
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  slug: Scalars['String'],
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  approximateDate?: Maybe<Scalars['String']>,
  place?: Maybe<Place>,
  online: Scalars['Boolean'],
  url?: Maybe<Scalars['String']>,
  promotionNote?: Maybe<Scalars['String']>,
  promotionToDate?: Maybe<Scalars['Date']>,
  featured: Scalars['Boolean'],
  canceled: Scalars['Boolean'],
  images?: Maybe<ImageConnection>,
  speakers?: Maybe<SpeakerConnection>,
  youtubePlaylistIds: Array<Scalars['String']>,
  id: Scalars['ID'],
  date: Scalars['String'],
  daysToStart: Scalars['Int'],
  description: Scalars['String'],
  rawDescription: Scalars['String'],
  image?: Maybe<Image>,
  isOver: Scalars['Boolean'],
  promoted: Scalars['Boolean'],
  promotionPrice?: Maybe<Scalars['String']>,
  promotionPricePerDay?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  partners?: Maybe<PartnerConnection>,
  topics: Array<Scalars['String']>,
};


export type EventImagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventSpeakersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventPartnersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type EventConnection = {
   __typename?: 'EventConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<EventEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type EventEdge = {
   __typename?: 'EventEdge',
  node?: Maybe<Event>,
  cursor: Scalars['String'],
};

export type EventUpdates = {
   __typename?: 'EventUpdates',
  newRemoteImages?: Maybe<RemoteImageConnection>,
  newRemoteSpeakers?: Maybe<RemoteSpeakerConnection>,
  remoteEventExtractions?: Maybe<RemoteEventExtractionConnection>,
};


export type EventUpdatesNewRemoteImagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventUpdatesNewRemoteSpeakersArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type EventUpdatesRemoteEventExtractionsArgs = {
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type IgnoreRemoteImagesInput = {
  remoteImageIds: Array<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteImagesPayload = {
   __typename?: 'IgnoreRemoteImagesPayload',
  remoteImages: Array<RemoteImage>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteSpeakersInput = {
  remoteSpeakerIds: Array<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type IgnoreRemoteSpeakersPayload = {
   __typename?: 'IgnoreRemoteSpeakersPayload',
  remoteSpeakers: Array<RemoteSpeaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Image = Node & {
   __typename?: 'Image',
  caption?: Maybe<Scalars['String']>,
  cropX?: Maybe<Scalars['Float']>,
  cropY?: Maybe<Scalars['Float']>,
  cropWidth?: Maybe<Scalars['Float']>,
  cropHeight?: Maybe<Scalars['Float']>,
  id: Scalars['ID'],
  image?: Maybe<Scalars['String']>,
  imageWidth?: Maybe<Scalars['Int']>,
  imageHeight?: Maybe<Scalars['Int']>,
  imageCard?: Maybe<Scalars['String']>,
  imageCardWidth?: Maybe<Scalars['Int']>,
  imageCardHeight?: Maybe<Scalars['Int']>,
  imagePreview?: Maybe<Scalars['String']>,
  imageCoverPreview?: Maybe<Scalars['String']>,
  imageCardPreview?: Maybe<Scalars['String']>,
  imageOriginal?: Maybe<Scalars['String']>,
};

export type ImageConnection = {
   __typename?: 'ImageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<ImageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type ImageEdge = {
   __typename?: 'ImageEdge',
  node?: Maybe<Image>,
  cursor: Scalars['String'],
};

export type ImportImagesImage = {
  remoteImageId?: Maybe<Scalars['ID']>,
  src: Scalars['String'],
  alt?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Scalars['Boolean']>,
};

export type ImportImagesInput = {
  eventId?: Maybe<Scalars['ID']>,
  images: Array<ImportImagesImage>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportImagesPayload = {
   __typename?: 'ImportImagesPayload',
  images: Array<Image>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportPartnersInput = {
  eventId?: Maybe<Scalars['ID']>,
  partners: Array<ImportPartnersPartner>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportPartnersPartner = {
  remoteImageId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  partnershipType: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type ImportPartnersPayload = {
   __typename?: 'ImportPartnersPayload',
  partners: Array<Partner>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersInput = {
  eventId?: Maybe<Scalars['ID']>,
  speakers: Array<ImportSpeakersSpeaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersPayload = {
   __typename?: 'ImportSpeakersPayload',
  speakers: Array<Speaker>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type ImportSpeakersSpeaker = {
  remoteSpeakerId?: Maybe<Scalars['ID']>,
  name: Scalars['String'],
  image?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventInput = {
  eventId?: Maybe<Scalars['ID']>,
  remotePages?: Maybe<Array<LoadRemoteEventRemotePageInput>>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventPayload = {
   __typename?: 'LoadRemoteEventPayload',
  remoteEventExtraction: RemoteEventExtraction,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type LoadRemoteEventRemotePageInput = {
  url: Scalars['String'],
  providedContent?: Maybe<Scalars['String']>,
};

export type Mutation = {
   __typename?: 'Mutation',
  addRemoteEvent?: Maybe<AddRemoteEventPayload>,
  ignoreRemoteImages?: Maybe<IgnoreRemoteImagesPayload>,
  ignoreRemoteSpeakers?: Maybe<IgnoreRemoteSpeakersPayload>,
  importImages?: Maybe<ImportImagesPayload>,
  importPartners?: Maybe<ImportPartnersPayload>,
  importSpeakers?: Maybe<ImportSpeakersPayload>,
  loadRemoteEvent?: Maybe<LoadRemoteEventPayload>,
  pruneRemoteEventExtraction?: Maybe<PruneRemoteEventExtractionsPayload>,
  createNewEventRequest?: Maybe<CreateNewEventRequestPayload>,
  createPayment?: Maybe<CreatePaymentPayload>,
  saveSpeaker?: Maybe<SaveSpeakerPayload>,
  newsletterSubscribe?: Maybe<NewsletterSubscribePayload>,
  updateEvent?: Maybe<UpdateEventPayload>,
  updateImage?: Maybe<UpdateImagePayload>,
  contactUs?: Maybe<ContactUsPayload>,
};


export type MutationAddRemoteEventArgs = {
  input: AddRemoteEventInput
};


export type MutationIgnoreRemoteImagesArgs = {
  input: IgnoreRemoteImagesInput
};


export type MutationIgnoreRemoteSpeakersArgs = {
  input: IgnoreRemoteSpeakersInput
};


export type MutationImportImagesArgs = {
  input: ImportImagesInput
};


export type MutationImportPartnersArgs = {
  input: ImportPartnersInput
};


export type MutationImportSpeakersArgs = {
  input: ImportSpeakersInput
};


export type MutationLoadRemoteEventArgs = {
  input: LoadRemoteEventInput
};


export type MutationPruneRemoteEventExtractionArgs = {
  input: PruneRemoteEventExtractionsInput
};


export type MutationCreateNewEventRequestArgs = {
  input: CreateNewEventRequestInput
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput
};


export type MutationSaveSpeakerArgs = {
  input: SaveSpeakerInput
};


export type MutationNewsletterSubscribeArgs = {
  input: NewsletterSubscribeInput
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput
};


export type MutationUpdateImageArgs = {
  input: UpdateImageInput
};


export type MutationContactUsArgs = {
  input: ContactUsInput
};

export type NewEventRequestType = {
   __typename?: 'NewEventRequestType',
  url: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  company?: Maybe<Scalars['String']>,
  listingType?: Maybe<Scalars['String']>,
  partnersLink?: Maybe<Scalars['String']>,
};

export type NewsletterSubscribeInput = {
  firstName: Scalars['String'],
  email: Scalars['String'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type NewsletterSubscribePayload = {
   __typename?: 'NewsletterSubscribePayload',
  result: Scalars['String'],
  message?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Node = {
  id: Scalars['ID'],
};

export type PageInfo = {
   __typename?: 'PageInfo',
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
  endCursor?: Maybe<Scalars['String']>,
};

export type Partner = Node & {
   __typename?: 'Partner',
  name: Scalars['String'],
  image?: Maybe<Image>,
  id: Scalars['ID'],
};

export type PartnerConnection = {
   __typename?: 'PartnerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<PartnerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type PartnerEdge = {
   __typename?: 'PartnerEdge',
  node?: Maybe<Partner>,
  cursor: Scalars['String'],
  partnershipType?: Maybe<PartnershipType>,
};

export type PartnershipType = Node & {
   __typename?: 'PartnershipType',
  name: Scalars['String'],
  id: Scalars['ID'],
};

export type Place = Node & {
   __typename?: 'Place',
  name: Scalars['String'],
  country: Scalars['String'],
  locality?: Maybe<Scalars['String']>,
  formattedAddress: Scalars['String'],
  googlePlaceId: Scalars['String'],
  lat: Scalars['Float'],
  lng: Scalars['Float'],
  id: Scalars['ID'],
};

export type PruneRemoteEventExtractionsInput = {
  remoteEventExtractionIds?: Maybe<Array<Scalars['ID']>>,
  eventIds?: Maybe<Array<Scalars['ID']>>,
  pruneRemoteImages: Scalars['Boolean'],
  pruneRemoteSpeakers: Scalars['Boolean'],
  clientMutationId?: Maybe<Scalars['String']>,
};

export type PruneRemoteEventExtractionsPayload = {
   __typename?: 'PruneRemoteEventExtractionsPayload',
  remoteEventExtractions: Array<RemoteEventExtraction>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Query = {
   __typename?: 'Query',
  node?: Maybe<Node>,
  eventUpdates: EventUpdates,
  remoteEventExtraction?: Maybe<RemoteEventExtraction>,
  remoteEventExtractions?: Maybe<RemoteEventExtractionConnection>,
  partnershipTypes: Array<PartnershipType>,
  events?: Maybe<EventConnection>,
  event?: Maybe<Event>,
  places?: Maybe<Array<Maybe<Place>>>,
  users2?: Maybe<Array<Maybe<User>>>,
  me?: Maybe<Scalars['String']>,
};


export type QueryNodeArgs = {
  id: Scalars['ID']
};


export type QueryEventUpdatesArgs = {
  slug: Scalars['String']
};


export type QueryRemoteEventExtractionArgs = {
  id: Scalars['ID']
};


export type QueryRemoteEventExtractionsArgs = {
  eventId?: Maybe<Scalars['ID']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEventsArgs = {
  country?: Maybe<Scalars['String']>,
  featured?: Maybe<Scalars['Boolean']>,
  canceled?: Maybe<Scalars['Boolean']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type QueryEventArgs = {
  slug?: Maybe<Scalars['String']>
};

export type RemoteEvent = Node & {
   __typename?: 'RemoteEvent',
  name?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  fromDate?: Maybe<Scalars['Date']>,
  toDate?: Maybe<Scalars['Date']>,
  place?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemoteEventExtraction = Node & {
   __typename?: 'RemoteEventExtraction',
  event?: Maybe<Event>,
  status: Scalars['String'],
  message?: Maybe<Scalars['String']>,
  createdAt: Scalars['DateTime'],
  updatedAt: Scalars['DateTime'],
  remoteEvent?: Maybe<RemoteEvent>,
  id: Scalars['ID'],
  remotePages?: Maybe<RemotePageConnection>,
  remoteImages?: Maybe<RemoteImageConnection>,
  remoteSpeakers?: Maybe<RemoteSpeakerConnection>,
};


export type RemoteEventExtractionRemotePagesArgs = {
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type RemoteEventExtractionRemoteImagesArgs = {
  status?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type RemoteEventExtractionRemoteSpeakersArgs = {
  status?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};

export type RemoteEventExtractionConnection = {
   __typename?: 'RemoteEventExtractionConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteEventExtractionEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteEventExtractionEdge = {
   __typename?: 'RemoteEventExtractionEdge',
  node?: Maybe<RemoteEventExtraction>,
  cursor: Scalars['String'],
};

export type RemoteImage = Node & {
   __typename?: 'RemoteImage',
  src: Scalars['String'],
  position: Scalars['String'],
  category?: Maybe<Scalars['String']>,
  alt?: Maybe<Scalars['String']>,
  link?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  width?: Maybe<Scalars['Int']>,
  height?: Maybe<Scalars['Int']>,
  id: Scalars['ID'],
  partnershipType: PartnershipType,
};

export type RemoteImageConnection = {
   __typename?: 'RemoteImageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteImageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteImageEdge = {
   __typename?: 'RemoteImageEdge',
  node?: Maybe<RemoteImage>,
  cursor: Scalars['String'],
};

export type RemotePage = Node & {
   __typename?: 'RemotePage',
  url: Scalars['String'],
  content?: Maybe<Scalars['String']>,
  contentProvided: Scalars['Boolean'],
  autodiscovered: Scalars['Boolean'],
  labels: Array<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemotePageConnection = {
   __typename?: 'RemotePageConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemotePageEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemotePageEdge = {
   __typename?: 'RemotePageEdge',
  node?: Maybe<RemotePage>,
  cursor: Scalars['String'],
};

export type RemoteSpeaker = Node & {
   __typename?: 'RemoteSpeaker',
  name?: Maybe<Scalars['String']>,
  bio?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  detailUrl?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  linkedinLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
  group?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
};

export type RemoteSpeakerConnection = {
   __typename?: 'RemoteSpeakerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<RemoteSpeakerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type RemoteSpeakerEdge = {
   __typename?: 'RemoteSpeakerEdge',
  node?: Maybe<RemoteSpeaker>,
  cursor: Scalars['String'],
};

export type SaveSpeakerInput = {
  name: Scalars['String'],
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['ID']>,
  linkedinLink?: Maybe<Scalars['String']>,
  facebookLink?: Maybe<Scalars['String']>,
  twitterLink?: Maybe<Scalars['String']>,
  youtubeLink?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type SaveSpeakerPayload = {
   __typename?: 'SaveSpeakerPayload',
  speaker?: Maybe<Speaker>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type Speaker = Node & {
   __typename?: 'Speaker',
  name: Scalars['String'],
  bio?: Maybe<Scalars['String']>,
  resume?: Maybe<Scalars['String']>,
  image?: Maybe<Image>,
  id: Scalars['ID'],
};

export type SpeakerConnection = {
   __typename?: 'SpeakerConnection',
  pageInfo: PageInfo,
  edges: Array<Maybe<SpeakerEdge>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type SpeakerEdge = {
   __typename?: 'SpeakerEdge',
  node?: Maybe<Speaker>,
  cursor: Scalars['String'],
};

export type UpdateEventInput = {
  name: Scalars['String'],
  alternativeName?: Maybe<Scalars['String']>,
  summary?: Maybe<Scalars['String']>,
  fromDate: Scalars['Date'],
  toDate: Scalars['Date'],
  url?: Maybe<Scalars['String']>,
  approximateDate?: Maybe<Scalars['String']>,
  online?: Maybe<Scalars['Boolean']>,
  canceled?: Maybe<Scalars['Boolean']>,
  placeId?: Maybe<Scalars['String']>,
  rawDescription: Scalars['String'],
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateEventPayload = {
   __typename?: 'UpdateEventPayload',
  event?: Maybe<Event>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateImageInput = {
  caption?: Maybe<Scalars['String']>,
  cropX?: Maybe<Scalars['Float']>,
  cropY?: Maybe<Scalars['Float']>,
  cropWidth?: Maybe<Scalars['Float']>,
  cropHeight?: Maybe<Scalars['Float']>,
  id?: Maybe<Scalars['ID']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type UpdateImagePayload = {
   __typename?: 'UpdateImagePayload',
  image?: Maybe<Image>,
  errors?: Maybe<Array<Maybe<ErrorType>>>,
  error?: Maybe<Scalars['String']>,
  clientMutationId?: Maybe<Scalars['String']>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  password: Scalars['String'],
  lastLogin?: Maybe<Scalars['DateTime']>,
  isSuperuser: Scalars['Boolean'],
  username: Scalars['String'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  email: Scalars['String'],
  isStaff: Scalars['Boolean'],
  isActive: Scalars['Boolean'],
  dateJoined: Scalars['DateTime'],
};

export type AddRemoteEventMutationVariables = {
  input: AddRemoteEventInput
};


export type AddRemoteEventMutation = (
  { __typename?: 'Mutation' }
  & { addRemoteEvent: Maybe<(
    { __typename?: 'AddRemoteEventPayload' }
    & { event: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'name' | 'slug'>
    )> }
  )> }
);

export type CreateNewEventRequestMutationVariables = {
  input: CreateNewEventRequestInput
};


export type CreateNewEventRequestMutation = (
  { __typename?: 'Mutation' }
  & { createNewEventRequest: Maybe<(
    { __typename?: 'CreateNewEventRequestPayload' }
    & { newEventRequest: Maybe<(
      { __typename?: 'NewEventRequestType' }
      & Pick<NewEventRequestType, 'url' | 'email' | 'company' | 'listingType' | 'partnersLink'>
    )>, errors: Maybe<Array<Maybe<(
      { __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SpeakerFragment = (
  { __typename?: 'Speaker' }
  & Pick<Speaker, 'id' | 'name' | 'bio' | 'resume'>
  & { image: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'image' | 'imageCoverPreview'>
  )> }
);

export type ImageFragment = (
  { __typename?: 'Image' }
  & Pick<Image, 'caption' | 'image' | 'imagePreview'>
);

export type PartnerFragment = (
  { __typename?: 'Partner' }
  & Pick<Partner, 'id' | 'name'>
  & { image: Maybe<(
    { __typename?: 'Image' }
    & ImageFragment
  )> }
);

export type EventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'alternativeName' | 'description' | 'summary' | 'date' | 'approximateDate' | 'fromDate' | 'toDate' | 'slug' | 'featured' | 'promoted' | 'canceled' | 'isOver' | 'topics' | 'url' | 'online' | 'youtubePlaylistIds'>
  & { place: Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'name' | 'country' | 'locality' | 'formattedAddress' | 'googlePlaceId'>
  )>, image: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'imageCard' | 'imageCardWidth' | 'imageCardHeight'>
    & ImageFragment
  )>, images: Maybe<(
    { __typename?: 'ImageConnection' }
    & Pick<ImageConnection, 'totalCount'>
    & { edges: Array<Maybe<(
      { __typename?: 'ImageEdge' }
      & { node: Maybe<(
        { __typename?: 'Image' }
        & ImageFragment
      )> }
    )>> }
  )>, speakers: Maybe<(
    { __typename?: 'SpeakerConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SpeakerEdge' }
      & { node: Maybe<(
        { __typename?: 'Speaker' }
        & SpeakerFragment
      )> }
    )>> }
  )>, partners: Maybe<(
    { __typename?: 'PartnerConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PartnerEdge' }
      & { partnershipType: Maybe<(
        { __typename?: 'PartnershipType' }
        & Pick<PartnershipType, 'id' | 'name'>
      )>, node: Maybe<(
        { __typename?: 'Partner' }
        & PartnerFragment
      )> }
    )>> }
  )> }
);

export type EventQueryVariables = {
  slug?: Maybe<Scalars['String']>,
  featuredEventsCount?: Maybe<Scalars['Int']>
};


export type EventQuery = (
  { __typename?: 'Query' }
  & { event: Maybe<(
    { __typename?: 'Event' }
    & EventFragment
  )>, featuredEvents: Maybe<(
    { __typename?: 'EventConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'EventEdge' }
      & { node: Maybe<(
        { __typename?: 'Event' }
        & EventPreviewFragment
      )> }
    )>> }
  )> }
);

export type EventPreviewFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'alternativeName' | 'description' | 'date' | 'slug' | 'featured' | 'promoted' | 'topics' | 'online'>
  & { place: Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'country' | 'locality'>
  )>, image: Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'imagePreview' | 'imageCardPreview'>
  )> }
);

export type EventsQueryVariables = {
  country?: Maybe<Scalars['String']>,
  first: Scalars['Int'],
  after?: Maybe<Scalars['String']>
};


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: Maybe<(
    { __typename?: 'EventConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<Maybe<(
      { __typename?: 'EventEdge' }
      & { node: Maybe<(
        { __typename?: 'Event' }
        & EventPreviewFragment
      )> }
    )>> }
  )> }
);

export type PlacesQueryVariables = {};


export type PlacesQuery = (
  { __typename?: 'Query' }
  & { places: Maybe<Array<Maybe<(
    { __typename?: 'Place' }
    & Pick<Place, 'country'>
  )>>> }
);

export const ImageFragmentDoc = gql`
    fragment image on Image {
  caption
  image
  imagePreview
}
    `;
export const SpeakerFragmentDoc = gql`
    fragment speaker on Speaker {
  id
  name
  bio
  resume
  image {
    image
    imageCoverPreview
  }
}
    `;
export const PartnerFragmentDoc = gql`
    fragment partner on Partner {
  id
  name
  image {
    ...image
  }
}
    ${ImageFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment event on Event {
  id
  name
  alternativeName
  description
  summary
  date
  approximateDate
  fromDate
  toDate
  slug
  featured
  promoted
  canceled
  isOver
  topics
  url
  online
  youtubePlaylistIds
  place {
    name
    country
    locality
    formattedAddress
    googlePlaceId
  }
  image {
    ...image
    imageCard
    imageCardWidth
    imageCardHeight
  }
  images {
    totalCount
    edges {
      node {
        ...image
      }
    }
  }
  speakers {
    edges {
      node {
        ...speaker
      }
    }
  }
  partners {
    edges {
      partnershipType {
        id
        name
      }
      node {
        ...partner
      }
    }
  }
}
    ${ImageFragmentDoc}
${SpeakerFragmentDoc}
${PartnerFragmentDoc}`;
export const EventPreviewFragmentDoc = gql`
    fragment eventPreview on Event {
  id
  name
  alternativeName
  description
  date
  slug
  featured
  promoted
  topics
  online
  place {
    country
    locality
  }
  image {
    imagePreview
    imageCardPreview
  }
}
    `;
export const AddRemoteEventDocument = gql`
    mutation addRemoteEvent($input: AddRemoteEventInput!) {
  addRemoteEvent(input: $input) {
    event {
      name
      slug
    }
  }
}
    `;
export type AddRemoteEventMutationFn = ApolloReactCommon.MutationFunction<AddRemoteEventMutation, AddRemoteEventMutationVariables>;
export type AddRemoteEventComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRemoteEventMutation, AddRemoteEventMutationVariables>, 'mutation'>;

    export const AddRemoteEventComponent = (props: AddRemoteEventComponentProps) => (
      <ApolloReactComponents.Mutation<AddRemoteEventMutation, AddRemoteEventMutationVariables> mutation={AddRemoteEventDocument} {...props} />
    );
    
export type AddRemoteEventProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddRemoteEventMutation, AddRemoteEventMutationVariables> & TChildProps;
export function withAddRemoteEvent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddRemoteEventMutation,
  AddRemoteEventMutationVariables,
  AddRemoteEventProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddRemoteEventMutation, AddRemoteEventMutationVariables, AddRemoteEventProps<TChildProps>>(AddRemoteEventDocument, {
      alias: 'addRemoteEvent',
      ...operationOptions
    });
};
export type AddRemoteEventMutationResult = ApolloReactCommon.MutationResult<AddRemoteEventMutation>;
export type AddRemoteEventMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRemoteEventMutation, AddRemoteEventMutationVariables>;
export const CreateNewEventRequestDocument = gql`
    mutation createNewEventRequest($input: CreateNewEventRequestInput!) {
  createNewEventRequest(input: $input) {
    newEventRequest {
      url
      email
      company
      listingType
      partnersLink
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateNewEventRequestMutationFn = ApolloReactCommon.MutationFunction<CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables>;
export type CreateNewEventRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables>, 'mutation'>;

    export const CreateNewEventRequestComponent = (props: CreateNewEventRequestComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables> mutation={CreateNewEventRequestDocument} {...props} />
    );
    
export type CreateNewEventRequestProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables> & TChildProps;
export function withCreateNewEventRequest<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewEventRequestMutation,
  CreateNewEventRequestMutationVariables,
  CreateNewEventRequestProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables, CreateNewEventRequestProps<TChildProps>>(CreateNewEventRequestDocument, {
      alias: 'createNewEventRequest',
      ...operationOptions
    });
};
export type CreateNewEventRequestMutationResult = ApolloReactCommon.MutationResult<CreateNewEventRequestMutation>;
export type CreateNewEventRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewEventRequestMutation, CreateNewEventRequestMutationVariables>;
export const EventDocument = gql`
    query event($slug: String, $featuredEventsCount: Int) {
  event(slug: $slug) {
    ...event
  }
  featuredEvents: events(featured: true, first: $featuredEventsCount) {
    edges {
      node {
        ...eventPreview
      }
    }
  }
}
    ${EventFragmentDoc}
${EventPreviewFragmentDoc}`;
export type EventComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventQuery, EventQueryVariables>, 'query'>;

    export const EventComponent = (props: EventComponentProps) => (
      <ApolloReactComponents.Query<EventQuery, EventQueryVariables> query={EventDocument} {...props} />
    );
    
export type EventProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventQuery, EventQueryVariables> & TChildProps;
export function withEvent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EventQuery,
  EventQueryVariables,
  EventProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EventQuery, EventQueryVariables, EventProps<TChildProps>>(EventDocument, {
      alias: 'event',
      ...operationOptions
    });
};
export type EventQueryResult = ApolloReactCommon.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query events($country: String, $first: Int!, $after: String) {
  events(canceled: false, country: $country, first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...eventPreview
      }
    }
  }
}
    ${EventPreviewFragmentDoc}`;
export type EventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EventsQuery, EventsQueryVariables>, 'query'> & ({ variables: EventsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EventsComponent = (props: EventsComponentProps) => (
      <ApolloReactComponents.Query<EventsQuery, EventsQueryVariables> query={EventsDocument} {...props} />
    );
    
export type EventsProps<TChildProps = {}> = ApolloReactHoc.DataProps<EventsQuery, EventsQueryVariables> & TChildProps;
export function withEvents<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EventsQuery,
  EventsQueryVariables,
  EventsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, EventsQuery, EventsQueryVariables, EventsProps<TChildProps>>(EventsDocument, {
      alias: 'events',
      ...operationOptions
    });
};
export type EventsQueryResult = ApolloReactCommon.QueryResult<EventsQuery, EventsQueryVariables>;
export const PlacesDocument = gql`
    query places {
  places {
    country
  }
}
    `;
export type PlacesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlacesQuery, PlacesQueryVariables>, 'query'>;

    export const PlacesComponent = (props: PlacesComponentProps) => (
      <ApolloReactComponents.Query<PlacesQuery, PlacesQueryVariables> query={PlacesDocument} {...props} />
    );
    
export type PlacesProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlacesQuery, PlacesQueryVariables> & TChildProps;
export function withPlaces<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlacesQuery,
  PlacesQueryVariables,
  PlacesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlacesQuery, PlacesQueryVariables, PlacesProps<TChildProps>>(PlacesDocument, {
      alias: 'places',
      ...operationOptions
    });
};
export type PlacesQueryResult = ApolloReactCommon.QueryResult<PlacesQuery, PlacesQueryVariables>;