import { Button, Card, CardBody, Alert, Form, Spinner, Label } from "reactstrap";
import { Field, Formik } from "formik";
import { gql } from "apollo-boost";
import * as Yup from 'yup';
import uniqueId from 'lodash/uniqueId';
import { InputField } from "components/InputField";
import { NewsletterSubscribeMutationComponent } from "generated/updateevent/apolloComponents";
import { useState } from "react";


export const NewsletterSubscribeMutation = gql`
  mutation NewsletterSubscribeMutation($input: NewsletterSubscribeInput!) {
    newsletterSubscribe(input: $input) {
      result
      message
    }
  }
`;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

export type Props = {
  className?: string,
};

const SubscribeCard: React.FunctionComponent<Props> = ({ className }) => {
  const [labelNameId] = useState(uniqueId('subscribe-name-'));
  const [labelEmailId] = useState(uniqueId('subscribe-email-'));
  const [message, setMessage] = useState<string>();
  const [status, setStatus] = useState<string>('WAITING');
  return (
    <Card className={`bg-primary text-white border-0 mb-3 subscribe ${className}`}>
      <CardBody className="p-5">
        <h2 className="h5 mb-2 text-white">Upcoming AI&nbsp;&amp;&nbsp;ML Events Newsletter</h2>
        <p className="text-white">Monthly to your email. No spam. Trusted by AI&nbsp;&amp;&nbsp;ML experts.</p>
        <div>
          {status === 'ERROR' && <Alert color="danger">{message}</Alert>}
          {(status === 'ERROR' || status === 'WAITING') && <NewsletterSubscribeMutationComponent>
            {newsletterSubscribe => (
              <Formik
                onSubmit={async (data, { setSubmitting }) => {
                  setSubmitting(true);
                  const response = await newsletterSubscribe({
                    variables: {
                      input: data
                    }
                  });
                  setStatus(response.data?.newsletterSubscribe?.result ?? '');
                  setMessage(response.data?.newsletterSubscribe?.message ?? '');
                }}
                initialValues={{firstName: '', email: ''}}
                validationSchema={SignupSchema}>
                {({ isSubmitting, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Label className="sr-only" for={labelNameId}>Your name</Label>
                    <Field name="firstName" placeholder="Your name" component={InputField} className="form-control mb-1" id={labelNameId} />
                    <Label className="sr-only" for={labelEmailId}>Your e-mail</Label>
                    <Field name="email" placeholder="Your e-mail" component={InputField} className="form-control mb-1" id={labelEmailId} />
                    <Button type="submit" color="success" disabled={isSubmitting}>
                      {isSubmitting && <><Spinner size="sm" /> Subscribing...</>}
                      {!isSubmitting && <>Subscribe</>}
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </NewsletterSubscribeMutationComponent>}
          {status === 'SUCCESS' && <>Well done! Please follow the instructions in the email to confirm your email address.</>}
        </div>
      </CardBody>
    </Card>
  );
};

export default SubscribeCard;
